var columns = [{
  title: '分类ID',
  dataIndex: 'id',
  key: 'id',
  width: '25%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '分类名称',
  dataIndex: 'name',
  key: 'name',
  width: '25%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: 'ICON',
  dataIndex: 'iconurl',
  key: 'iconurl',
  width: '25%',
  scopedSlots: {
    customRender: 'iconurl'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  //   fixed: "right",
  width: '25%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };