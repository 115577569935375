var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "分类名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入分类名称!"
        }]
      }],
      expression: "[\n                          'name',\n                          {\n                              rules: [\n                                  { required: true, message: '请输入分类名称!' },\n                              ],\n                          },\n                      ]"
    }],
    attrs: {
      autoComplete: "off",
      disabled: _vm.isDisabled,
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "分类ICON"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["iconurl", {
        rules: [{
          required: true,
          message: "请上传图片"
        }],
        initialValue: ""
      }],
      expression: "[\n                'iconurl',\n                {\n                    rules: [\n                        {\n                            required: true,\n                            message: '请上传图片',\n                        },\n                    ],\n                    initialValue: '',\n                },\n            ]"
    }],
    attrs: {
      listType: "picture-card",
      max: 1,
      memorySize: "10kb"
    }
  }), _c("div", {
    staticStyle: {
      color: "#969696",
      "font-size": "12px",
      height: "12px",
      "line-height": "12px"
    }
  }, [_vm._v(" 推荐尺寸: 18*18 ")])], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.updateStatus == 0 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _vm._e(), _vm.updateStatus == 1 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("确定"))])], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };